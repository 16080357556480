import React, {useState} from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
//import '@fortawesome/fontawesome-free/css/all.min.css';
//import 'bootstrap-css-only/css/bootstrap.min.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
//import {AwesomeButton} from 'react-awesome-button';
import 'mdbreact/dist/css/mdb.css';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import ReactGA from 'react-ga';

const ContactUs = (props) => {

  const [contactname, setContactName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [captchatoken, setCaptchaToken] = useState('');
 
  const sitekey = '6LeMua8ZAAAAALBUvyUegmfNcQqk9YwWwgtfvfow';

  const onChange = (value) => {
    setCaptchaToken(value);
  }  

  const handleInputChange = inputName => value => {
    const nextValue = value;
    this.setState({
      [inputName]: nextValue
    });
  };

  const handleContactNameChange = value => {
    const nextValue = value;
    setContactName(value);
  };

  const handleCompanyChange = value => {
    const nextValue = value;
    setCompany(value);
  };

  const handleEmailChange = value => {
    const nextValue = value;
    setEmail(value);
  };

  const handlePhoneChange = value => {
    const nextValue = value;
    setPhone(value);
  };

  const handleMessageChange = value => {
    const nextValue = value;
    setMessage(value);
  };

  const handleSubmit = (event) => {
      
    event.preventDefault();
    
    let url = process.env.REACT_APP_BACKEND_URL + '/enquire-client';
    
  
    try{
      axios.post(url, {
        email: email,
        contactname: contactname,
        company: company,
        phone: phone,
        message: message,
        responsetoken: captchatoken
      })
      .then(response => {
          alert(response.data);
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
      //alert("Email successfully sent!");
      this.props.click();
      }
    catch{
      console.log('error submitting');
    };
  }

  const handleSubmitManualClick = () => {
      
    //event.preventDefault();
    
    let url = process.env.REACT_APP_BACKEND_URL + '/enquire-client';
    
  
    try{
      axios.post(url, {
        email: email,
        contactname: contactname,
        company: company,
        phone: phone,
        message: message,
        responsetoken: captchatoken
      })
      .then(response => {
          alert(response.data);
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
      //alert("Email successfully sent!");
      ReactGA.event({
        category: 'User',
        action: 'Contacts Email Sent'
      });
      
      this.props.click();
      }
    catch{
      console.log('error submitting');
    };
  }

return (
<MDBContainer>
  <MDBRow>
    <MDBCol md="4">
      <form>
        <span className="text-content">Let's keep in touch</span><br />
        <span className="text-content-sub">Shoot us an email and we'll get back to you the soonest.</span>
        <div className="grey-text">
          <MDBInput label="Your name" icon="user" group name="contactname" id="contactname" type="text" validate error="wrong"
            success="right" getValue={handleContactNameChange}/>
          <MDBInput label="Your email" icon="envelope" group name="email" id="email" type="email" validate error="wrong"
            success="right" getValue={handleEmailChange} />
          <MDBInput label="Your phone" icon="mobile" group name="phone" id="phone" type="text" validate error="wrong" success="right" 
          getValue={handlePhoneChange}/>
          <MDBInput type="textarea" rows="2" label="Your message" icon="pencil" name="message" id="message"
          getValue={handleMessageChange}/>
        </div>
        
          <div>
            <ReCAPTCHA
              sitekey={sitekey}
              onChange={onChange}
            />
          </div>
          
          <div>
            <MDBBtn outline color='secondary' type="primary" onClick={handleSubmitManualClick}>
              Send  <MDBIcon icon='paper-plane' className='ml-1' />
            </MDBBtn>
          </div>
        
      </form>
    </MDBCol>
  </MDBRow>
</MDBContainer>
);
};

export default ContactUs;