import React from 'react';
import './PowerStations.css';
import {useSpring, animated} from 'react-spring';
import ContactUs from '../../components/Mailer/ContactUs';

const PowerStations = (props) => {
    const props1 = useSpring({opacity: 1, from: {opacity: 0}});
    return (
        <div>
            {/* <animated.div style={props1}>
                <div className="ps-carousel-container">
                    <div className="ps-bg-img-container ps-bg-01">
                        <div style={{opacity:"0.45"}} className="ps-bg-text-container">
                            <h2 className="ps-mask" onClick={props.click}>Build, Own, Operate & Transmit</h2>
                            <br />
                            <h3><a href="/apc-staff" className="ps-link">Contact us</a> and see how we can power your industry.</h3>
                            <p className="ps-photo-origin"> 
                                Photo courtesy of Caterpillar Motoren
                            </p>
                        </div>
                    </div>
                </div>
            </animated.div> */}
            
                <div>
                    <div className="ps-content-wrapper">
                        <br />
                        <br />
                        <h2 className="aboutus-header-text">
                            The APC Difference
                        </h2>
                        <p className="aboutus-content-text">
                            APC prides itself as a veteran in the Power Station industry. We have been building and maintaining Power Stations since 1996. 
                        </p>
                        <p className="aboutus-content-text">
                            We build our power stations / power plants with only the most reliable generator brands and the latest technology stack. 
                            These translate to optimal fuel efficiency and ultimately result to lower cost of energy. We transport, install, and 
                            commission to ALL of Australia.
                        </p>
                        <br />
                        <br />
                        <h2 className="aboutus-header-text">
                            BOOT
                        </h2>
                        <p className="aboutus-content-text">
                            Under our Build, Own, Operate, and Transmit (BOOT) contract, we assume all the financial and technical risks associated 
                            with the power station / power plant project. With 35 years of experience, you can rely on our financial stability and 
                            technical expertise in all phases of the project.
                        </p>
                        <div className="ps-photo-grid">
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/welding01.jpg"} alt="Power Station - Frame Welding"/>
                            </div>
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/frame01.jpg"} alt="Power Station - Frame"/>
                            </div>
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/fanbase01.jpg"} alt="Power Station - Radiator Fan"/>
                            </div>
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/genset01.jpg"} alt="Power Station - Generators"/>
                            </div>
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/louvre01.jpg"} alt="Power Station - Louvres"/>
                            </div>
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/panel01.jpg"} alt="Power Station - Switch Panels"/>
                            </div>
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/Const01.jpg"} alt="Power Station - Commissioning"/>
                            </div>
                            <div>
                                <img className="ps-photo" src={process.env.REACT_APP_BACKEND_URL + "/powerstation/Const02.jpg"} alt="Power Station - Commissioning"/>
                            </div>
                        </div>
                        <br />
                        <br />
                        <h2 className="aboutus-header-text">
                            High Availability Operation
                        </h2>
                        <p className="aboutus-content-text">
                            Our highly qualified and in-house trained engineers are deployed (on a shifting basis) in our power stations / power plants 
                            to monitor and maintain the plant operation 24/7. 
                        </p>
                        <p className="aboutus-content-text">
                            With engineers present on-site, you can have the flexibility of ramping up or scaling down the power supply depending on your
                            changing capacity demand. 
                        </p>
                        <br />
                        <br />
                    </div>
                </div>
                <div>
                    <br />
                    <br />
                    
                        <div className="contactus-container"><ContactUs /></div>
                    
                </div>

        </div>
            
        
    )
}

export default PowerStations;