import React from "react";
import "./app.css"
import Toolbar from "../../components/Toolbar/Toolbar";
import AboutUs from "../../contents/AboutUs/AboutUs";
import PowerStations from "../../contents/PowerStations/PowerStations";
import Footer from "../../contents/Footer/Footer";
import {Switch,Route} from "react-router-dom";
import ProductListV2 from "../../contents/Products/ProductListV2";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import TemporaryDrawer from '../../components/SideDrawer/TemporaryDrawer';
import RenderNavBarLinks from '../../components/Toolbar/RenderLink';
import ProductPage from "../../contents/Products/ProductPage";
import ContactsPage from '../../contents/Contacts/ContactsPage';
import PortableListV2 from "../../contents/Products/PortableListV2";
import { useParams } from "react-router";
import ReactGA from 'react-ga';
import HomepageV2 from "../../contents/HomePage/HomepageV2";

const App = () => {
    ReactGA.initialize('UA-174498279-1');
    const params = useParams();
    return (
      <div className="container-app">
        <Toolbar />
        <div className="container-body">
          <div className="apc-sticky">
            <Nav
              className="apc-custom"
              activeKey="/home"
              >
                  <Nav.Item>
                      <TemporaryDrawer />
                  </Nav.Item>
                  <div className="filler"/>
                  <RenderNavBarLinks />
                  {/*<div className="spacer-20"/>
                  <RenderLinkWithSubMenu />
                  <div className="filler"/> */}
            </Nav>
          </div>
          <div>
            <Switch>
              <Route path="/" exact component={HomepageV2}/>
              <Route path="/about-us" component={AboutUs}/>
              <Route path="/power-stations">
                <PowerStations/>
              </Route>
              <Route path="/diesel-generators">
                <ProductListV2 menugroup="Products" category="Diesel Generator"/>
              </Route>
              <Route path="/portable-generators">
                <PortableListV2 menugroup="Portables" category="Portable Petrol Generator"/>
              </Route>
              <Route path="/silenced-diesel-generators">
                <ProductListV2 menugroup="Products" category="Silenced Generator"/>
              </Route>
              <Route path="/preloved-equipment">
                <ProductListV2 menugroup="Products" category="Pre-Loved Equipment"/>
              </Route>
              <Route path="/apc-staff">
                <ContactsPage />
              </Route>
              <Route path="/homepagev2">
                <HomepageV2/>
              </Route>
              <Route path="/product/:productID" component={ProductPage} />
            </Switch>
          </div>
          
        </div>  
        <div className="container-footer">
            <Footer/>
        </div>
      </div>
    );
  }

export default App;