import React, {Component} from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import './EnquireProduct.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {AwesomeButton} from 'react-awesome-button';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import ReactGA from 'react-ga';

class EnquireProductForm extends Component {
    constructor(props, context) {
      super(props, context);

      this.state = {
        contactname : '',
        company : '',
        email : '',
        phone : '',
        itemname : props.ItemName,
        captchatoken : '',
        sitekey : '6LeMua8ZAAAAALBUvyUegmfNcQqk9YwWwgtfvfow'
    };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      //this.handleAwesomeButtonSubmit = this.handleAwesomeButtonSubmit.bind(this);
      this.onChange = this.onChange.bind(this);
    }
  
    handleChange(event) {
      let nam = event.target.name;
      let val = event.target.value;
      this.setState({[nam]: val});
    }

    onChange(value) {
      //console.log("Captcha value:", value);
      this.setState({captchatoken: value});
    }
  
    async handleSubmit(event) {
      
      event.preventDefault();
      
      let url = process.env.REACT_APP_BACKEND_URL + '/enquire-product';
      
    
      try{
        await axios.post(url, {
          email: this.state.email,
          contactname: this.state.contactname,
          company: this.state.company,
          itemname: this.state.itemname,
          phone: this.state.phone,
          responsetoken: this.state.captchatoken
        })
        .then(response => {
            alert(response.data);
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
        //alert("Email successfully sent!");

        ReactGA.event({
          category: 'User',
          action: 'Contacts Email Sent'
        });

        this.props.click();
        }
      catch{
        console.log('error submitting');
      };
    }

    async AwesomeButtonHandleSubmit() {
      
      let url = process.env.REACT_APP_BACKEND_URL + '/enquire-product';
      
      try{
        await axios.post(url, {
          email: this.state.email,
          contactname: this.state.contactname,
          company: this.state.company,
          itemname: this.state.itemname,
          phone: this.state.phone,
          responsetoken: this.state.captchatoken
        })
        .then(response => {
            alert(response);
  
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
        //alert("Email successfully sent!");
        this.props.click();  
        }
      catch{
        console.log('error submitting');
      };
    }

    handleInputChange = inputName => value => {
      const nextValue = value;
      this.setState({
        [inputName]: nextValue
      });
    };


    render() {
      return (
        <MDBContainer>
          <MDBRow>
            <MDBCol md="10">
              <form onSubmit={this.handleSubmit}>
                <span className="text-content">Interested?</span><br />
                <span className="text-content-sub">Shoot us an email and we'll sort this one out for you.</span>
                <div className="grey-text">
                  <MDBInput label="Your name" id="contactname" name="contactname" icon="user" type="text" group validate error="wrong"
                    success="right" getValue={this.handleInputChange("contactname")} />
                  <MDBInput label="Your email" id="email" name="email" icon="envelope" type="email" group validate error="wrong"
                    success="right"  getValue={this.handleInputChange("email")} />
                  <MDBInput label="Your phone #" id="phone" name="phone" icon="phone" type="text" group validate error="wrong" 
                  success="right" getValue={this.handleInputChange("phone")} />  
                  <MDBInput label="Your company's name" id="company" name="company" icon="building-o" type="text" group validate error="wrong" 
                  success="right" getValue={this.handleInputChange("company")} />
                </div>
                
                  <div>
                    <ReCAPTCHA
                      sitekey={this.state.sitekey}
                      onChange={this.onChange}
                    />
                  </div>
                  
                  <div>
                    {/* <AwesomeButton type="primary" onPress={this.AwesomeButtonHandleSubmit}><FontAwesomeIcon icon={faPaperPlane} /> Send Enquiry</AwesomeButton> */}
                    <MDBBtn outline color='secondary' type="submit">
                      Send <MDBIcon icon='paper-plane' className='ml-1' />
                    </MDBBtn>
                  </div>
                
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }
}

export default EnquireProductForm; 