import React from 'react';
import CategoryCardV2 from './CategoryCardV2';
import './CategoryCardV2.css';


const CategoryPanel = (props) => {
    return (
        <div className='categorypanel-frame'>
            <div className='categorypanel-grid'>
                <div>
                    <CategoryCardV2 
                    bgimage={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/silencedgenset.png'}
                    titlewhite='SILENCED'
                    titleyellow='DIESEL GENERATORS'
                    subtitle='10KVA - 2500KVA'
                    name='Silenced Diesel Generators'
                    link='/silenced-diesel-generators'
                    />
                </div>
                <div>
                    <CategoryCardV2 
                    bgimage={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/dieselgenset.png'}
                    titlewhite='DIESEL'
                    titleyellow='GENERATORS'
                    subtitle='10KVA - 2500KVA'
                    name='Diesel Generators'
                    link='/diesel-generators'
                    />
                </div>
                <div>
                    <CategoryCardV2 
                    bgimage={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/portable-generators.png'}
                    titlewhite='PORTABLE'
                    titleyellow='GENERATORS'
                    subtitle='0.9KVA - 9.4KVA'
                    name='Portable Generators'
                    link='/portable-generators'
                    />
                </div>
                <div>
                    <CategoryCardV2 
                    bgimage={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/compreesor.png'}
                    titlewhite='AIR'
                    titleyellow='COMPRESSORS'
                    subtitle='New & Used (Low Hours)'
                    name='Air Compressors'
                    link='/air-compressors'
                    />
                </div>
                <div>
                    <CategoryCardV2 
                    bgimage={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/prelovedeqpt.png'}
                    titlewhite='PRE-LOVED'
                    titleyellow='EQUIPMENT'
                    subtitle='Used Machinery (Low Hours)'
                    name='Pre-Loved Equipment'
                    link='/preloved-equipment'
                    />
                </div>
                <div>
                    <CategoryCardV2 
                    bgimage={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/powerstation_moranbah.png'}
                    titlewhite='POWER'
                    titleyellow='STATIONS'
                    subtitle='1-200 Megawatts (Build, Own, Operate, Transfer)'
                    name='Powerstations'
                    link='/power-stations'
                    />
                </div>

            </div>
        </div>
    )
}

export default CategoryPanel;