import React from 'react';
import './PowerForRent.css';

const PowerForRent = (props) => {
    return (
        <div className='powerforrent-container'>
            <img className='powerforrent-img' src={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/forhire-graphics.png'} alt='Power for Rent!' />
            <div className='powerforrent-contact-text'>CONTACT US</div>
            <div className='powerforrent-email-text'>steve@australianpower.net</div>
            <div className='powerforrent-phone1-text'>0408700111</div>
            <div className='powerforrent-phone2-text'>1-800-287-769</div>
            <div className='powerforrent-title-text'>POWER<br/>FOR RENT!</div>
            <div className='powerforrent-subtitle-text'>Events<br/>Short Term Projects<br/>Job Sites</div>
            <div className='powerforrent-footer-text'>Hire our top-of-the-line generators (from 10KVA - 250KVA) to suit<br/> your project schedule (and budget).</div>

        </div>
    )
}

export default PowerForRent;
