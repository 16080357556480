import React from 'react';
import CategoryPanel from '../../components/CategoryCards/CategoryPanel';
import Postboard from '../../components/Postboard/Postboard';
import './Homepage.css';
const HomepageV2 = (props) => {
    return (
        <div className='homepagev2-container'>
            <div className='category-ribbon'>
                <span className='category-text'>WE SUPPLY</span>
            </div>
            <CategoryPanel/>
            <Postboard/>
        </div>
    )
}

export default HomepageV2;