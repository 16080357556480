import React from 'react';
import './ContactCard.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

const ContactCard = (props) => {
    return (
        <Card style={{ width: '175px' }}>
            <Card.Header className="contacts-name-box"><span className="contacts-name">{props.contact.Name}</span></Card.Header>
            <Card.Body>
            <ListGroup variant="flush">
                <ListGroup.Item className="contacts-position">{props.contact.Position}</ListGroup.Item>
                <ListGroup.Item className="contacts-details"><FontAwesomeIcon icon={faPhone}/> {props.contact.PhoneNumber}</ListGroup.Item>
                <ListGroup.Item className="contacts-email">{props.contact.Email}</ListGroup.Item>
                <ListGroup.Item className="contacts-details"><FontAwesomeIcon icon={faMapMarkerAlt}/> {props.contact.company_location.Address}</ListGroup.Item>
            </ListGroup>
            </Card.Body>
        </Card>
    )
}

export default ContactCard;
