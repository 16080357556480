import React from 'react';
import './Postboard.css';
import PowerForRent from './PowerForRent/PowerForRent';
import WeHaveMoved from './WeHaveMoved/WeHaveMoved';

const Postboard = (props) => {
    return (
        <div className='postboard-frame'>
            <div>
               <WeHaveMoved/> 
            </div>
            <div>
                <PowerForRent/>
            </div>
        </div>
    )
}

export default Postboard;