import React from 'react';
import '../../components/ContactCard/ContactCard.css';
import ContactUs from '../../components/Mailer/ContactUs';
import ContactCard from '../../components/ContactCard/ContactCard';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ReactGA from 'react-ga';

const ContactsPage = () => {
    const GET_CONTACTS = gql`{
        contacts(where:{
            Active:true,
            ContactType:"APC"
          }){
            id
            Name
              Position
              PhoneNumber
              Email
              ContactType
              company_location {
              Name
              Main
              Address
            }
          }
    }
    `;
    
    const { loading, error, data } = useQuery(GET_CONTACTS);
    if (loading) return (
      "Loading.."
      )
    if (error) return `Error! ${error.message}`;

    ReactGA.initialize('UA-174498279-1');
    ReactGA.pageview('Contacts - APC Staff');

    return (
        <div className="contacts-wrapper-grid">
            <div>
                <div className="contactus-container"><ContactUs /></div>
            </div>
            <div>
                <br />
                <br />
                <div><h4 className="contacts-header-title">APC Corporate Office</h4></div>
                <div className="contact-cards-wrapper">
                    {
                        data.contacts.map(contact =>
                            <div className="contact-card-container">
                                <ContactCard contact={contact} />
                            </div>
                        )    
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactsPage;
