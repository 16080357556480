import React from 'react';
import './ProductCardV2.css';
//import {Link} from "react-router-dom";
import NumberFormat from 'react-number-format';

const ProductCardV2 = (props) => {
    return (
        <div className='product-card-container'>
            
            <div className='card-container' onClick={props.click}>
                <div className='top-card-photo-container'>
                    <img className='product-img' src={props.bgimage} alt={props.titletext}/>
                </div>
                <div className='bottom-card-details-container'>
                    <span className='title-text'>{props.titletext}</span>
                    <span className='subtitle-text'>{props.subtitletext}</span>
                </div>
                <div className='productcardv2-price-container'>
                    <span className='price-text'><NumberFormat value={props.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                    <span className='extgst-text'>{props.exgsttext}</span>
                </div>
            </div>
            
        </div>
    )
}

export default ProductCardV2;