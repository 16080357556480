import React from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import './Toolbar.css';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCommentAlt} from '@fortawesome/free-solid-svg-icons';

const RenderNavBarLinks = () => {
    const GET_LINKBARS = gql`{
        mainBars(where: {Active: true, WithSub: false}) {
          id
          AppOrder
          Name
          Link
          Description
          WithSub
        }
      }
      `;

    const { loading, error, data} = useQuery(GET_LINKBARS);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    
    
    return (
      <React.Fragment>  
        <Nav.Item>
          <Nav.Link as={Link} to={data.mainBars[0].Link} className="cutom-nav-mobile-icon"><FontAwesomeIcon icon={faHome}/></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to={data.mainBars[1].Link} className="cutom-nav-mobile-icon"><FontAwesomeIcon icon={faUser}/></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/apc-staff" className="cutom-nav-mobile-icon"><FontAwesomeIcon icon={faCommentAlt}/></Nav.Link>
        </Nav.Item>
      </React.Fragment>
    );
}

export default RenderNavBarLinks;