import React from 'react';
import Card from 'react-bootstrap/Card';
import './ProductFilter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';

const ProductFilter = (props) => {
    return (
        <div className="apc-categories-container">
            <Card>
                <Card.Header className="apc-categories-header">Filter(s)</Card.Header>
                <Card.Text>
                    <div className="apc-textbox-container">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></span>
                            </div>
                            {
                               (props.defvalue != "") && <input type="text" name="search" className="form-control apc-textbox" defaultValue={props.defvalue} onChange={props.search} /> 
                            }
                            {
                               (props.defvalue == "") && <input type="text" name="search" className="form-control apc-textbox" placeholder="search" onChange={props.search} /> 
                            }
                        </div>
                    </div>
                    <div className="custom-control custom-checkbox apc-checkbox-container">
                        {props.brandnew && <input type="radio" value={true} name="brandnew" className="custom-control-input" id="brand new" defaultChecked onClick={props.change}/>}
                        {!props.brandnew && <input type="radio" value={true} name="brandnew" className="custom-control-input" id="brand new" onClick={props.change}/>}
                        <label className="custom-control-label" for="brand new">Brand New</label>
                    </div>
                    <div className="custom-control custom-checkbox apc-checkbox-container">
                        {props.brandnew && <input type="radio" value={false} name="brandnew" className="custom-control-input" id="used" onClick={props.change}/>}
                        {!props.brandnew && <input type="radio" value={false} name="brandnew" className="custom-control-input" id="used" defaultChecked  onClick={props.change}/>}
                        <label className="custom-control-label" for="used">Used</label>
                    </div>
                    <div className="apc-textbox-container">
                        <Button variant="primary" onClick={props.click}>Go</Button>
                    </div>
                </Card.Text>    
            </Card>
        </div>
    );
}

export default ProductFilter;