import React from 'react';
import './CategoryCardV2.css';
import {Link} from "react-router-dom";

const CategoryCardV2 = (props) => {
    return (
        <div className='category-frame'>
            <Link to={props.link}>
                <div className='category-card'>
                    <img className='category-card-bg-img' src={props.bgimage} alt={props.name}/>
                    <div className='category-card-top-shade'>
                        <div className='category-card-text-container'>
                            <span className='category-card-title-white'>{props.titlewhite}</span><br/>
                            <span className='category-card-title-yellow'>{props.titleyellow}</span><br/>
                            <span className='category-card-subtitle'>{props.subtitle}</span><br/>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default CategoryCardV2;