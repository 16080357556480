import React from 'react';
import './WeHaveMoved.css';

const WeHaveMoved = (props) => {
    return (
        <div className='moved-container'>
           <div><img className='moved-img' src={process.env.REACT_APP_BACKEND_URL + '/homepage/categories/moved-graphics-.png'} alt='We have moved!'/></div>
           <div className='maintext-container'>
               <span className='main-text'>WE HAVE<br/>MOVED!</span>
            </div>
            <div className='addresstext-container'>
               <span className='address-text'>1699 Ipswich Road<br/>Rocklea 4106 QLD</span>
            </div>
            <div className='companytext-container'>
               <span className='company-text'>Australian Power Corporation</span>
            </div>
        </div>
    )
}

export default WeHaveMoved;