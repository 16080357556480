import React from 'react'
import RenderCompanyLogo from '../Logo';
//import RenderCompanySpiel from '../Spiel';
//import RenderCompContactBold from '../CompContact';
//import RenderCompContactNarrow from '../CompContact/CompContactNarrow';
import "./Toolbar.css";

const ToolbarTop = props => {
    return (
        <div className="toolbar-top">
            <div className="toolbartop-box"><RenderCompanyLogo/></div>
            {/* <div className="spacer"/>
            <div className="toolbartop-box"><RenderCompanySpiel /></div>
            <div className="spacer"/>
            <div className="toolbartop-box">
                <img className="boltseparator" src={process.env.REACT_APP_BACKEND_URL + "/logo/APC-Separator.png"} alt="APC Lightning Bolt"/>
            </div>
            <div className="toolbartop_box_contact">
                <RenderCompContactBold/>
                <RenderCompContactNarrow/>
            </div> */}
          </div>
    );
}

export default ToolbarTop;
