import React, {useState}  from 'react';
import './WoySelect.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";


const options = ["PORTABLE PETROL GENERATOR", "PORTABLE DIESEL GENERATOR", "PORTABLE INVERTER GENERATOR", "PORTABLE WATER PUMP"];
const SortBy = ({ children, options, value, onChange }) => (
    <div className="container">
      <select
        className="woyselect-select-overlay"
        id="filter-select"
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <label className="custom" htmlFor="filter-select">
        {children}
      </label>
    </div>
  );

const WoySelect = (props) => {
    const [sortValue, setSortValue] = useState(options[0]);
    
    const onChange = (e) => {
      //const chosenCategory = e.target.value;
      setSortValue(e.target.value);
      props.change(e);
    }
    
    return (
        <div className='woyselect-filter-container'>
            <form>
              <div className='woyselect-filter-button'>
                  <SortBy 
                      options={options}
                      value={sortValue}
                      onChange={onChange}
                  >
                  <span className='woyselect-browse-icon'><FontAwesomeIcon icon={faSort} /></span>
                  <span className='woyselect-browse-text'>{sortValue}</span>
                  </SortBy>
              </div>
            </form>
        </div>
    )
}

export default WoySelect;