import React, {useState} from 'react';
import WoyPaginationHook from '../../components/Pagination/WoyPaginationHook';
import gql from 'graphql-tag';
import { useQuery } from  '@apollo/react-hooks';
import ProductCardV2 from './ProductCardV2';
import ProductDetailModal from '../../components/Modal/CustomModal';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';
import './ProductCard.css';
import './Paginator.scss';
//import CategoryFilter from '../../components/ProductFilter/CategoryFilter';
//import ProductFilter from '../../components/ProductFilter/ProductFilter';
//import PriceFilter from '../../components/ProductFilter/PriceFilter';
import ReactGA from 'react-ga';
import WoySelect from '../../components/WoySelect/WoySelect';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

const ProductList = (props) => {
  const [show, setShow] = useState(false);
  const [selectedProduct, selectProduct] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState(props.category);
  const [search, setSearch] = useState("");
  const [searchevt, setSearchEvt] = useState("");
  
 
  const handleCategoryInputChange = (e) => {
      setCategory(e.target.value);
  }

  const handleSearchInputChange = (event)=> {
      setSearchEvt(event.target.value);
  }

  const GET_PRODUCTS = gql`{
    productCategories(where:{
      menugroup:"${props.menugroup}"
    }){	
      id
     Name
     Description
    },

    products(sort:"created_at:DESC", where:{
      Active:true,
      BrandNew:true,
      Description_contains:"${search}",
      product_categories: {Name: "${category}"}
    }){
      id
      Name
      Model
      Description
      ShortDesc
      BrandNew
      Condition
      Price
      StockQty
      created_at
      OnSale
      SalePrice
      SaleTag
      EcomTag1
      EcomLink1
      FeaturePhoto{
        id
        provider
        url
      }
      product_categories{
        id
        Name
        Description
      }
      productPhoto{
        id
        provider
        url
      }
      
    }
  }
`;
const { loading, error, data } = useQuery(GET_PRODUCTS);
if (loading) return (
  <div>
    <Spinner animation="border" variant="success" />
    <p>Loading...</p>
  </div>
  )
if (error) return `Error! ${error.message}`;

  const {next, prev, jump, currentData, maxPage, begin, end} = WoyPaginationHook(data.products,8,currentPage,setCurrentPage);
  const currData = currentData();

  ReactGA.initialize('UA-174498279-1');
  ReactGA.pageview(category);

  return (
      <div className="productlist-wrapper">
        <div className="productlist-search-container">
        {
          (props.menugroup==="Portables") && <WoySelect change={handleCategoryInputChange}/>
        }
        
        </div>
        <div>
          <div className="container-productlist-pageoptions">
            <div className="container-paginator">
              <b>{data.products.length}</b> product(s) | {(data.products.length > 0) && <span>showing <b>{begin} - {end}</b></span>} 
            </div>
            {
              (maxPage > 1) && 
                  <div className="container-paginator"><Paginator maxPage={maxPage} currentPage={currentPage} jump={jump} next={next} prev={prev} pageNeighbours={2}/></div>
            }
          </div>
          <div id="product" className="container-grid">
          {
            currData.map((product)=>
              <div>  
                <ProductCardV2 
                  titletext = {product.Name}
                  subtitletext = {product.ShortDesc}
                  price = {product.OnSale ? product.SalePrice : product.Price}
                  bgimage = {process.env.REACT_APP_BACKEND_URL + product.FeaturePhoto.url}
                  exgsttext = {'EX GST'}

                  click={ () => {
                      setShow(true);
                      selectProduct(product);
                      ReactGA.event({
                        category: 'User',
                        action: `Viewed Product - ${product.Name}`
                      });
                    }
                  }
                />
              </div>
            )
          } 
          <ProductDetailModal product={selectedProduct} show={show} hide={() => setShow(false)} />
        </div>
      </div>
      </div>
  )
} 

const fetchPageNumbers = (totalPages, currentPage, pageNeighbours) => {
   /**
   * totalNumbers: the total page numbers to show on the control
   * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
   */
  const totalNumbers = (pageNeighbours * 2) + 3;
  const totalBlocks = totalNumbers + 2;

  if (totalPages > totalBlocks) {

    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

    let pages = range(startPage, endPage);

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = (totalPages - endPage) > 1;
    const spillOffset = totalNumbers - (pages.length + 1);

    switch (true) {
      // handle: (1) < {5 6} [7] {8 9} (10)
      case (hasLeftSpill && !hasRightSpill): {
        const extraPages = range(startPage - spillOffset, startPage - 1);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
        break;
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      case (!hasLeftSpill && hasRightSpill): {
        const extraPages = range(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
        break;
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      case (hasLeftSpill && hasRightSpill):
      default: {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
        break;
      }
    }

    return [1, ...pages, totalPages];

  }

  return range(1, totalPages);

}

const Paginator = ({maxPage, currentPage, jump, next, prev, pageNeighbours}) => {
  
  const pages = fetchPageNumbers(maxPage, currentPage, pageNeighbours);

  return (
    <Pagination>
      { pages.map((page, index) => {

        if (page === LEFT_PAGE) return (
          <Pagination.Prev className="page-item" onClick={()=>prev} />
        );

        if (page === RIGHT_PAGE) return (
          <Pagination.Next className="page-item" onClick={()=>next} />
        );

        return (
          <Pagination.Item key={index} className={`page-item${ page === currentPage ? ' active' : ''}`} onClick={()=>jump(page)}>
           {page}
          </Pagination.Item>
        );

        }) }
    </Pagination>
  );
}

export default ProductList;
